<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>تقديم طلب</h3></div>

    <v-divider></v-divider>
    <v-container>
      <div>
        <v-row
          ><h2 class="mt-10">الأسئله الشائعة عن طلبات شركه المياه</h2></v-row
        >
         <v-row>
          <v-col cols="12" lg="8" sm="10" xs="10">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel
                v-for="(item, i) in ArrayOFAnElsherka"
                :key="i"
                class="changeTitleStyle"
              >
                <v-expansion-panel-header class="black--text titleQes" expand-icon="mdi-menu-down ">
                  {{ item.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul v-if="item.subtext">
                    <li v-for="i in item.subtext" :key="i">{{ i }}</li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
                <div>
              <h3 class="green-text mt-10">
                لتقديم الطلب أضغط علي الرابط التالي
           
              <a href="https://ecp.hcww.com.eg/" target="_blank" rel="noopener noreferrer">أضغط هنا </a>
                 </h3>
            </div>
          </v-col>
          <v-col cols="12" lg="4" sm="4" xs="4">
            <AllTrenders></AllTrenders>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import AllTrenders from "../../components/projectAndTenders.vue";
export default {
  components: { AllTrenders },
  data() {
    return {
      panel: [0],
      ArrayOFAnElsherka: [
        {
          title: " الاحتياجات اللازمة للتعاقد علي العداد التنظيمي والمباني الانشائية",
           subtext: ["موافقة الجهة المختصة علي دخول المياه والصرف الصحي محل الأشتراك وصورة من مستندات الترخيص الصادرة من الجهات الرسمية  ",
           "	نسخة معتمدة من الرسومات المعمارية لكافة الأداور المبنية بمستندات الترخيص الصادرة من الجهات الرسمية في حالة الحاجة لها .",
           "	صورة بطاقة الرقم القومي للاشخاص الطبيعين ",
           "	المستندات المؤيدة لحيازته لمحل الأشتراك (مستند ملكية-ترخيص مباني-عقد ايجار) أو اي مستند يؤيد الحيازة .",
           "	صورة من تعاقد أو ايصال مرافق أخري (كهرباء,غاز, تليفونات) ان وجد.",
           "	تنازل من المشترك السابق في حالة طلب نقل الاشتراك باسمه .",
           "	تنازل من الورثة في حالة طلب نقل الاشتراك من اسم المشترك المتوفي الي طالب الاشتراك الجديد مرفقا به صورة من اعلام الوراثة (الاصل للاطلاع)",
           "	في حالة النشاط غير المنزلي سواء كان خدمي او تجاري أو صناعي أو سياحي يلزم الحصول علي مستند يفيد نوع النشاط والموافقة الكتابية من مالك العقار أو اتحاد الشاغلين حسب الاحوال والتي تفيد بعدم الممانعه في توصيل مياه الشرب للوحده موضوع الطلب من بريزه العقار وصورة من السجل التجاري لم يمضي عليه 6 أشهر",
           "	وفي حالة تصحيح وضع وصلة مياه أو صرف صحي يلزم الحصول علي كشوف مشتملات من تاريخ انشاء العقار مبين بها مكونات العقار وتاريخ الربط أو أي مستندات أخري تدل علي تاريخ انشاء العقار ",
           "	أي مستند آخر تري الشركة طلبه ويكون ضروريا لصحة وسلامة عملية التوصيل ."],
        },
        {
          title: "المستندات المطلوبة للتعاقد علي العداد الكودي",

          subtext: ["•	صورة الرقم القومي (البطاقة الشخصية) ", "•	صورة من عقد الملكية المستخرج من المحكمة أو صورة عقد الايجار الموثق بالنسبة للمدينه أو عقد بيع ابتدائي بالنسبة للقري ",
          "	رسم كروكي معتمد من مكتب هندسي أو مهندس نقابي يكون موضحا به الأبعاد والمساحة وعدد الآدوار وعدد الوحدات وعدد الغرف بكل وحدة وكذلك الحدود ",
          "	صورة ايصال من أي خدمات أخري (كهرباء-غاز) ان وجدت",
          "	أخذ اقرار علي المواطن صاحب العقار او من ينوب عنه (بتوكيل رسمي موثق في الشهر العقاري مع ارفاق صورة من التوكيل) بانه قام بالتعدي علي خطوط شبكة شركة مياه الشرب والصرف الصحي وأن العقار غير مقام علي مناطق أثرية أو املاك دولة وغير مخالف لقيود الارتفاع المقررة طبقا لقانون الطيران المدني واذا ظهر خلاف ذلك يكون مسئول مسئولية مدنية وجنائية مع حفظ حقوق الشركة في ازالة ورفع العداد والتوصيلة والرجوع علي المواطن المذكور بالاجراءات اللازمة ضده دون ادني مسئولية في ذلك علي الشركة كما يتضمن الاقرار موافقة المواطن علي محاسبته بطريقة الممارسة ولحين تركيب العداد وبعد توقيع المواطن علي الاقرار المعد لذلك والخاص بتوكيل خدمتي مياه الشرب او الصرف الصحي طبقا لقرار وزير الاسكان والمرافق والمجتمعات العمرانية رقم 377 لعام 2016 بتاريخ 31/5/2016 او من ينوب عنه بتوكيل رسمي موثق في الشهر العقاري ويتم الاحتفاظ بصورة من التوكيل الرسمي العام او اصل التوكيل الخاص بملف التوصيل"
          ],
        },
        {
          title: "المستندات المطلوبة في الصرف الصحي للحصول علي الخدمة ",
          subtext: ["إحضار الموافقة التنظيمية من الوحده المحليه (منزلي – تجاري) وبالنسبة للنشاط التجاري يجب توضيح نوع النشاط في الموافقة التنظيمية",
          "	الحصول علي تصريح حفر من الجهة المختصة بالمنطقة التابع لها الموقع المراد توصيل الصرف الصحي",
          "رسومات هندسيه للمسقط الافقي للعقار للدور الارضي موضح عليه طريقة الصرف الصحي ومععتمد من مهندس نقابي  ",
          "أ-عدد 1 رسم موقع للعقار معتمد من كهندس نقابي ب- عدد 2  رسومات هندسيه للمحل موضح عليها طريقة الصرف الصحي والموقع العام للمحل ومعتمد من مهندس نقابي "],
        },
        /*{
          title: "عدد الأقساط المدونه بالفاتوره؟",

          subtext: [
            "درجة الحرارة ",
            "مدة التفاعل بين الكلور والماء (فترة التلامس) ",
            " درجة تركيز أيون الهيدروجين",
            "عكارة الماء",
            " قلوية وحامضية الماء",
            "وجود مركبات الحديد والمنجنيز",
            " نوع وعدد البكتريا المراد القضاء عليها",
            "إضافة الكلور",
          ],
        },
        {
          title: "طلب تقسيط العداد",
          subtext: [
            "درجة الحرارة ",
            "مدة التفاعل بين الكلور والماء (فترة التلامس) ",
            " درجة تركيز أيون الهيدروجين",
            "عكارة الماء",
            " قلوية وحامضية الماء",
            "وجود مركبات الحديد والمنجنيز",
            " نوع وعدد البكتريا المراد القضاء عليها",
            "إضافة الكلور",
          ],
        },

        {
          title: "ما الاجراءات التي تتخذها الشركه إذا لم يتم دفع الفاتوره؟ ",
          subtext: [
            "درجة الحرارة ",
            "مدة التفاعل بين الكلور والماء (فترة التلامس) ",
            " درجة تركيز أيون الهيدروجين",
            "عكارة الماء",
            " قلوية وحامضية الماء",
            "وجود مركبات الحديد والمنجنيز",
            " نوع وعدد البكتريا المراد القضاء عليها",
            "إضافة الكلور",
          ],
        },*/
      ],
    };
  },
};
</script>
<style >
h3 {
  font-family: Arial, Helvetica, sans-serif;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
}
.titleQes
{
  font-weight: bold;
}
</style>