<template>
  <div>
    <v-card class="changeTitleStyleDark">
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab class="titleCard" v-for="item in itemsTraning" :key="item">
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" style="max-height: 600px;overflow: scroll;">
        <v-tab-item v-for="item in itemsTraning" :key="item">
          <v-card v-if="item.id == 1" class="changeTitleStyle">
            <v-card-text>
              <div
                v-for="i in homeNews"
                :key="i"
                class="justify-center font-weight-bold liCard"
              >
                <v-row>
                  <v-col cols="3">
                    <div
                        v-for="(elem, index) in i.news_images"
                        :key="elem"
                        v-bind:selected="index == 1"
                        v-show="elem.main_image == 1"
                      >
                        <v-img
                        width="45px"
                        height="50px"
                          :src="
                            'https://backend.ascww.org/api/news/image/' +
                              elem.path
                          "
                        />
                      </div>
                  </v-col>
                  <v-col cols="9" class="mt-1">
                    <router-link
                      :to="{ name: 'News', params: { userId: i.slug } }"
                      class="linkSearch"
                    >
                      {{ i.title }}
                    </router-link></v-col
                  >
                </v-row>
                <hr color="white" />
              </div>
            </v-card-text>
          </v-card>
          <v-card v-if="item.id == 2" class="changeTitleStyle">
            <v-card-text class="pa-10">
              <div
                v-for="i in allTenders"
                :key="i"
                class="justify-center font-weight-bold liCard"
              >
                <v-row>
                  <v-col cols="3">
                    <v-img
                      width="45px"
                      height="50px"
                      src="https://staticfpu.bu.edu.eg/NewsImgs/1396872143.jpg"
                    ></v-img>
                  </v-col>
                  <v-col cols="9">
                    <router-link :to="i.link" class="linkSearch">
                      {{ i.item }}
                    </router-link></v-col
                  >
                </v-row>
                <hr color="white" />
              </div>
            </v-card-text>
          </v-card>
          <v-card v-if="item.id == 3" class="changeTitleStyle">
            <v-card-text>
              <div
                v-for="i in allProject"
                :key="i"
                class="justify-center mr-10 font-weight-bold liCard"
              >
                <v-row>
                  <v-col cols="3">
                    <div
                        v-for="(elem, index) in i.project_images"
                        :key="elem"
                        v-bind:selected="index == 1"
                        v-show="elem.main_image == 1"
                      >
                        <v-img
                        width="45px"
                        height="50px"
                          :src="
                            'https://backend.ascww.org/api/projects/image/' +
                              elem.path
                          "
                        />
                      </div>
                  </v-col>
                  <v-col cols="9">
                    <router-link
                      :to="{ name: 'ShowMore', params: { userId: i.slug } }"
                      class="linkSearch"
                    >
                      {{ i.title }}
                    </router-link></v-col
                  >
                </v-row>
                <hr color="white" />
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
export default {
  created(){
     this.$store.dispatch("findSelectedNewsHome");
     this.$store.dispatch("findHomeProjects");
  },
  data() {
    return {
      tab: null,
      itemsTraning: [
        {
          title: "الاخبار",
          id: "1",
        },
        {
          title: "المناقصات",
          id: "2",
        },

        {
          title: "مشروعات",
          id: "3",
        },
      ],
    };
  },
  computed: {
     homeNews(){
      var arr = this.$store.state.homeNewsArray;
      return arr.reverse();

    },
    allNewsFun() {
      var arr = this.$store.state.newsArrayStore;
      return arr.reverse();
    },
    allProject() {
      var arr = this.$store.state.projects;
      return arr.reverse();
    },
    allTenders() {
      var arr = this.$store.state.allTenders;
      return arr.reverse();
    },
  },
};
</script>
<style>
.titleCard {
  letter-spacing: normal !important;
  font-weight: bold;
  background: none !important;
  padding: 3px;
}
.changeTitleStyleDark {
  background: rgba(222, 184, 135, 0.385) !important;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
  font-family: inherit !important;
  font-weight: bold;
  color: gray !important;
}
.linkSearch {
  text-decoration: none;
  color: blueviolet;
}
</style>
